import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/common/Layout";
import { MDXRenderer } from "gatsby-plugin-mdx";

const CookiesPolicy = ({ data }) => {
  const cookiesPolicy = data?.allFile?.nodes?.[0]?.childMdx;

  return (
    <Layout>
      <div className="news-list-page">
        <div className="row">
          <div className="col-sm-6">
            <p className="sub-text back-link mb-1">
              {cookiesPolicy?.frontmatter?.subTitle}
            </p>
            <h2 className="mb-4">{cookiesPolicy?.frontmatter?.title}</h2>
          </div>
        </div>

        <div className="mb-5">
          <MDXRenderer>{cookiesPolicy?.body}</MDXRenderer>
        </div>
      </div>
    </Layout>
  );
};
export default CookiesPolicy;

export const query = graphql`
  query cookiesPolicy {
    allFile(filter: { absolutePath: { regex: "/cookies-policy.mdx/" } }) {
      nodes {
        childMdx {
          frontmatter {
            title
            subTitle
          }
          body
        }
      }
    }
  }
`;
